import { useEffect, useRef, useState } from 'react';
import './OtherAwardCarousel.css'
import PopBtn from '../svg/PopBtn'
import { ARTIST_URL } from '../config';

// import { Carousel } from 'react-responsive-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css";

const POSITIONS=[
    '30vw',
    '-4vw',
    '-28vw',
    '-47vw',
    '-69vw',
    '-98.5vw',
    '-133vw',
    '-168vw',
    '-191vw',
    '-208vw'
]
export default function Component(props){

    const [currentIndex,setcurrentIndex]=useState(0)
    const [skipCount,setskipCount]=useState(0)
    const [isPopup,setisPopup]=useState(null)
    const skipCountRef = useRef(skipCount)
    skipCountRef.current=skipCount

    const handleItemClick=(e,p)=>()=>{
        setcurrentIndex(e)
        setskipCount(1)
        setisPopup(p)
    }

    const handleScrollto=(e)=>()=>{
        setcurrentIndex(e)
        setskipCount(1)
    }
    useEffect(()=>{
        const myInterval= setInterval(
            ()=>{
                if(skipCountRef.current>0){
                    setskipCount(0)
                }else{
                    setcurrentIndex(e=>(e+1)%10)
                }
            }
        ,5000)
        return()=>{
            clearInterval(myInterval)
        }
    },[])

    return(
        <div className='w-full flex flex-col items-center move-up'>
            <div className={`w-[80vw] max-w-[1100px] mt-[-5vw] relative phone-hidden`}>
                <img className='w-full' src='/images/bar.png' alt='bar'/>
                {[0,1,2,3,4,5,6,7,8,9].map(item=>
                    <div key={item} className='absolute top-0 h-full w-[10%] cursor-pointer' style={{left:`${item*10}%`}} onClick={handleScrollto(item)}/>
                )}
            </div>
            <div className='relative w-full h-[20vw] flex flex-row items-center phone-hidden' style={{overflow:'hidden'}}>
                <div className='flex flex-row h-[100%] absolute gap-[2vw] pt-[1vw]' style={{transition:'1s all ease',left:POSITIONS[currentIndex]}}>
                    <div onClick={handleScrollto(0)} className='h-[100%] relative cursor-pointer' style={{width:undefined,aspectRatio:1135/580}}>
                        <img className='h-full' style={{objectFit:'contain'}} src='/images/award_card/award_3/Award_3-1.png' alt='artist'/>
                        <div className='absolute left-[4%] bottom-[8%] w-[26%] h-[70%]' onClick={handleItemClick(0,1)}/>
                        <div className='absolute left-[32%] bottom-[8%] w-[26%] h-[70%]' onClick={handleItemClick(0,2)}/>
                        <div className='absolute left-[67.5%] bottom-[8%] w-[26%] h-[70%]' onClick={handleItemClick(0,3)}/>
                    </div>
                    <div onClick={handleScrollto(1)} className='h-[100%] relative cursor-pointer' style={{width:undefined,aspectRatio:795/580}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-2.png' alt='artist'/>
                        <div className='absolute left-[7.8%] bottom-[8%] w-[36%] h-[70%]' onClick={handleItemClick(1,4)}/>
                        <div className='absolute left-[56.5%] bottom-[8%] w-[36%] h-[70%]' onClick={handleItemClick(1,5)}/>
                    </div>
                    <div onClick={handleScrollto(2)} className='h-[100%] relative cursor-pointer' style={{width:undefined,aspectRatio:518/579}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-3.png' alt='artist'/>
                        <div className='absolute left-[11%] bottom-[8%] w-[55%] h-[70%]' onClick={handleItemClick(2,6)}/>
                    </div>
                    <div onClick={handleScrollto(3)} className='h-[100%] relative cursor-pointer' style={{width:undefined,aspectRatio:395/579}}>
                        <img className='h-full cursor-pointer' src='/images/award_card/award_3/Award_3-4.png' alt='artist'/>
                        <div className='absolute left-[11%] bottom-[8%] w-[76%] h-[70%]' onClick={handleItemClick(3,7)}/>
                    </div>
                    <div onClick={handleScrollto(4)} className='h-[100%] relative cursor-pointer' style={{width:undefined,aspectRatio:795/580}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-5.png' alt='artist'/>
                        <div className='absolute left-[7.8%] bottom-[8%] w-[36%] h-[70%]' onClick={handleItemClick(4,8)}/>
                        <div className='absolute left-[56.5%] bottom-[8%] w-[36%] h-[70%]' onClick={handleItemClick(4,9)}/>
                    </div>
                    <div onClick={handleScrollto(5)} className='h-[100%] relative cursor-pointer' style={{width:undefined,aspectRatio:795/581}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-6.png' alt='artist'/>
                        <div className='absolute left-[7.8%] bottom-[8%] w-[36%] h-[70%]' onClick={handleItemClick(5,10)}/>
                        <div className='absolute left-[56.5%] bottom-[8%] w-[36%] h-[70%]' onClick={handleItemClick(5,11)}/>
                    </div>
                    <div onClick={handleScrollto(6)} className='h-[100%] relative cursor-pointer' style={{width:undefined,aspectRatio:1113/580}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-7.png' alt='artist'/>
                        <div className='absolute left-[4%] bottom-[8%] w-[26%] h-[70%]' onClick={handleItemClick(6,13)}/>
                        <div className='absolute left-[33%] bottom-[8%] w-[26%] h-[70%]' onClick={handleItemClick(6,14)}/>
                        <div className='absolute left-[69%] bottom-[8%] w-[26%] h-[70%]' onClick={handleItemClick(6,12)}/>
                    </div>
                    <div onClick={handleScrollto(7)} className='h-[100%] relative cursor-pointer' style={{width:undefined,aspectRatio:795/580}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-8.png' alt='artist'/>
                        <div className='absolute left-[7.8%] bottom-[8%] w-[36%] h-[70%]' onClick={handleItemClick(7,15)}/>
                        <div className='absolute left-[56.5%] bottom-[8%] w-[36%] h-[70%]' onClick={handleItemClick(7,16)}/>
                        
                        <img className='absolute w-[3vw] top-[32%] right-[0%] cursor-pointer' onClick={handleItemClick(7,16)} src='/images/play.png' alt='play'/>
                        <img className='absolute w-[3vw] top-[32%] right-[49%] cursor-pointer' onClick={handleItemClick(7,15)} src='/images/play.png' alt='play'/>
                    </div>
                    <div onClick={handleScrollto(8)} className='h-[100%] relative cursor-pointer' style={{width:undefined,aspectRatio:395/579}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-9.png' alt='artist'/>
                        <div className='absolute left-[11%] bottom-[8%] w-[76%] h-[70%]' onClick={handleItemClick(8,17)}/>
                    </div>
                    <div onClick={handleScrollto(9)} className='h-[100%] relative cursor-pointer' style={{width:undefined,aspectRatio:451/579}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-10.png' alt='artist'/>
                        <div className='absolute left-[11%] bottom-[8%] w-[67%] h-[70%]' onClick={handleItemClick(9,18)}/>
                    </div>
                </div>
            </div>


            <div className='relative w-[80vw] flex flex-col items-center justify-center gap-8 web-hidden'>
                    <div className='relative' style={{width:'100%',height:undefined,aspectRatio:1135/580}}>
                        <img className='h-full' style={{objectFit:'contain'}} src='/images/award_card/award_3/Award_3-1.png' alt='artist'/>
                        <div className='absolute left-[4%] bottom-[8%] w-[26%] h-[70%] cursor-pointer' onClick={handleItemClick(0,1)}/>
                        <div className='absolute left-[32%] bottom-[8%] w-[26%] h-[70%] cursor-pointer' onClick={handleItemClick(0,2)}/>
                        <div className='absolute left-[67.5%] bottom-[8%] w-[26%] h-[70%] cursor-pointer' onClick={handleItemClick(0,3)}/>
                    </div>
                    <div className='relative' style={{width:undefined,height: 'calc(80vw/1135*580)',aspectRatio:795/580}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-2.png' alt='artist'/>
                        <div className='absolute left-[7.8%] bottom-[8%] w-[36%] h-[70%] cursor-pointer' onClick={handleItemClick(1,4)}/>
                        <div className='absolute left-[56.5%] bottom-[8%] w-[36%] h-[70%] cursor-pointer' onClick={handleItemClick(1,5)}/>
                    </div>
                    <div className='relative' style={{width:undefined,height: 'calc(80vw/1135*580)',aspectRatio:518/579}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-3.png' alt='artist'/>
                        <div className='absolute left-[11%] bottom-[8%] w-[55%] h-[70%] cursor-pointer' onClick={handleItemClick(2,6)}/>
                    </div>
                    <div className='relative' style={{width:undefined,height: 'calc(80vw/1135*580)',aspectRatio:395/579}}>
                        <img className='h-full cursor-pointer' src='/images/award_card/award_3/Award_3-4.png' alt='artist'/>
                        <div className='absolute left-[11%] bottom-[8%] w-[76%] h-[70%] cursor-pointer' onClick={handleItemClick(3,7)}/>
                    </div>
                    <div className='relative' style={{width:undefined,height: 'calc(80vw/1135*580)',aspectRatio:795/580}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-5.png' alt='artist'/>
                        <div className='absolute left-[7.8%] bottom-[8%] w-[36%] h-[70%] cursor-pointer' onClick={handleItemClick(4,8)}/>
                        <div className='absolute left-[56.5%] bottom-[8%] w-[36%] h-[70%] cursor-pointer' onClick={handleItemClick(4,9)}/>
                    </div>
                    <div className='relative' style={{width:undefined,height: 'calc(80vw/1135*580)',aspectRatio:795/581}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-6.png' alt='artist'/>
                        <div className='absolute left-[7.8%] bottom-[8%] w-[36%] h-[70%] cursor-pointer' onClick={handleItemClick(5,10)}/>
                        <div className='absolute left-[56.5%] bottom-[8%] w-[36%] h-[70%] cursor-pointer' onClick={handleItemClick(5,11)}/>
                    </div>
                    <div className='relative' style={{width:undefined,height: 'calc(80vw/1135*580)',aspectRatio:1113/580}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-7.png' alt='artist'/>
                        <div className='absolute left-[4%] bottom-[8%] w-[26%] h-[70%] cursor-pointer' onClick={handleItemClick(6,13)}/>
                        <div className='absolute left-[33%] bottom-[8%] w-[26%] h-[70%] cursor-pointer' onClick={handleItemClick(6,14)}/>
                        <div className='absolute left-[69%] bottom-[8%] w-[26%] h-[70%] cursor-pointer' onClick={handleItemClick(6,12)}/>
                    </div>
                    <div className='relative' style={{width:undefined,height: 'calc(80vw/1135*580)',aspectRatio:795/580}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-8.png' alt='artist'/>
                        <div className='absolute left-[7.8%] bottom-[8%] w-[36%] h-[70%] cursor-pointer' onClick={handleItemClick(7,15)}/>
                        <div className='absolute left-[56.5%] bottom-[8%] w-[36%] h-[70%] cursor-pointer' onClick={handleItemClick(7,16)}/>
                        <img className='absolute w-[10%] top-[34%] right-[1%] cursor-pointer' onClick={handleItemClick(7,16)} src='/images/play.png' alt='play'/>
                        <img className='absolute w-[10%] top-[34%] right-[50%] cursor-pointer' onClick={handleItemClick(7,15)} src='/images/play.png' alt='play'/>
                    </div>
                    <div className='relative' style={{width:undefined,height: 'calc(80vw/1135*580)',aspectRatio:395/579}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-9.png' alt='artist'/>
                        <div className='absolute left-[11%] bottom-[8%] w-[76%] h-[70%] cursor-pointer' onClick={handleItemClick(8,17)}/>
                    </div>
                    <div className='relative' style={{width:undefined,height: 'calc(80vw/1135*580)',aspectRatio:451/579}}>
                        <img className='h-full' src='/images/award_card/award_3/Award_3-10.png' alt='artist'/>
                        <div className='absolute left-[11%] bottom-[8%] w-[67%] h-[70%] cursor-pointer' onClick={handleItemClick(9,18)}/>
                    </div>
            </div>
            {isPopup&&<div className='fixed inset-0 bg-[#00000090] flex justify-center items-center' style={{zIndex:99}}>
                    <div className='w-[90%] max-w-[1100px] relative v-tube-pop-move'>
                        <img className='w-full' src={`/images/popup/pop_up_award_3/pop_up_awards_3-${isPopup<10?0:''}${isPopup}.png`} alt='popup'/>
                        <div className='absolute top-0 right-0 cursor-pointer w-[8%] h-[10%]' onClick={()=>{setisPopup(null)}}/>
                        {(isPopup===15||isPopup===16)&&<div className='absolute left-[7.9%] bottom-[22.5%] w-[39%] flex justify-center items-center  overflow-hidden' style={{borderRadius:16,borderWidth: 2, borderColor: '#F4D681'}}>
                            <video playsInline 
                                key={`${isPopup}.mp4`}
                                preload="auto"
                                controls={true}
                                controlsList={"nodownload noremoteplayback"}
                                autoPlay={true}
                                loop={false}
                                name="media"
                                className="w-[100%] h-[100%] rounded overflow-hidden"
                            >
                                <source src={`/images/popup/video/${isPopup}.mp4`} type="video/mp4"/>
                            </video>
                        </div>}
                        <PopBtn className="pop-up-btn" style={{position: 'absolute', width:'40%', top:'75%', left:'51%'}}/>
                        <div onClick={props.navigateToNewPage(ARTIST_URL[`3-${isPopup}`])} className="pop-up-background flex justify-center items-center" style={{position: 'absolute', width:'40%', top:'75%', left:'51%'}}>
                            <img src='/images/btn_text.png' className='w-[90%]' style={{height:undefined,aspectRatio:1392/98}} alt='text'/>
                        </div>
                    </div>
            </div>}
        </div>
    )
}



// export default function Component(props){
//     return(
//         <div className="carousel-container-2 mt-[-10vw]">
//             <div className="carousel-track-2">
                
//                 <div className='w-[60%]'>
//                     <img className='w-full cursor-pointer' src='/images/card3.png' alt='card'/>
//                 </div>
//                 <div className='w-[40%]'>
//                     <img className='w-full cursor-pointer' src='/images/card4.png' alt='card'/>
//                 </div>



//                 <div className='w-[60%]'>
//                     <img className='w-full cursor-pointer' src='/images/card3.png' alt='card'/>
//                 </div>
//                 <div className='w-[40%]'>
//                     <img className='w-full cursor-pointer' src='/images/card4.png' alt='card'/>
//                 </div>

//             </div>
//         </div>
//     )
// }