import './InfiniteCarousel.css'


const cardDetails={
    0:{imgUrl:'/images/artist.png'},
}
   


function CarouselItem({ imgUrl, imgTitle }) {
    return (
      <div className="carousel-card">
        <img src={imgUrl} alt={'carosel image'}></img>
      </div>
    );
  }



export default function Component(props){
    return(
        <div className="carousel-container">
            <div className="carousel-track">
                {Object.keys(cardDetails).map((detailKey) => {
                    return (
                        <CarouselItem key={detailKey}
                            imgUrl={cardDetails[detailKey].imgUrl}
                        ></CarouselItem>
                    );
                })}
                {Object.keys(cardDetails).map((detailKey) => {
                    return (
                        <CarouselItem key={detailKey}
                            imgUrl={cardDetails[detailKey].imgUrl}
                        ></CarouselItem>
                    );
                })}
            </div>
        </div>
    )
}