import './Background.css'

// import Lottie from "lottie-react";
// import lottieJson from "./bg_lottie.json";
// import { useEffect, useState } from 'react';


export default function Component(props){
    // const [windowSize, setWindowSize] = useState(getWindowSize());      
    // function getWindowSize() {
    //     const {innerWidth, innerHeight} = window;
    //     return {innerWidth, innerHeight};
    // }
    // useEffect(() => {
    //     function handleWindowResize() {
    //         setWindowSize(getWindowSize());
    //     }
        
    //     window.addEventListener('resize', handleWindowResize);
        
    //     return () => {
    //         window.removeEventListener('resize', handleWindowResize);
    //     };
    // }, []);
    return(
        <div className="fixed top-0 left-0 w-[100vw] h-[100vh] flex justify-center items-center" style={{overflow:'hidden'}}>
            {/* <div style={windowSize.innerHeight/1080>windowSize.innerWidth/1920?{position:'relative',width:undefined,height:'100%',aspectRatio:1920/1080}:{position:'relative',width:'100%',height:undefined,aspectRatio:1920/1080}}>
                <Lottie 
                    loop={true}
                    animationData= {lottieJson}
                    className='w-full h-full'
                    style={{}}
                />
            </div> */}
            {/* <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div> */}

            {/* <div className="space2" style={{transform: 'rotate(45deg)'}}>
                <div className="particle"></div>
                <div className="particle"></div>
            </div>
            <div className="space2" style={{transform: 'rotate(315deg)'}}>
                <div className="particle"></div>
                <div className="particle"></div>
            </div> */}

            {/* <img src='/images/background.gif' style={{width:"100%",height:'100%',objectFit:'cover'}}/> */}

            {/* <div className="glow-container">
                <div className="ball"></div>
                <div className="ball1"></div>
            </div> */}

                <video ref={props.videoRef} playsInline 
                    preload="auto"
                    muted={true}
                    controls={false}
                    controlsList={"nodownload noremoteplayback nofullscreen"}
                    autoPlay={true}
                    loop={true}
                    name="media"
                    className="w-full h-full" style={{objectFit:'cover'}}
                >
                    <source src={`./images/bg.mp4`} type="video/mp4"/>
                </video>
        </div>
    )
}