
import './App.css';
import Background from './components/Background';
import { ARTIST_URL, CONTENT } from './config';
import InfiniteCarousel from './components/InfiniteCarousel'
import OtherAwardCarousel from './components/OtherAwardCarousel'
import { useRef, useState } from 'react';
import PopBtn from './svg/PopBtn'


function App() {
  const [isPopup,setisPopup]=useState(null)

  const navigateToNewPage=(url)=>()=>{
    window.open(url, '_blank');
  }

  const videoRef=useRef(null)
  // const [isInit,setisInit]=useState(false)
  // const init=()=>{
  //     setisInit(true)
  //     videoRef.current.play()
  // }
  
  return (
    <div className="w-[100vw] h-[100vh] flex flex-col justify-start items-center bg-black overflow-hidden myfont">
      <div className='w-full h-full flex flex-col justify-start items-center relative overflow-y-auto'>
        <div className='w-full flex flex-col justify-start items-center relative'>
          {/*background*/}
          <div className='gradient-bg left-0 top-0 h-full w-full absolute '/>
          <Background videoRef={videoRef}/>
          {/*end of background*/}





          {/*main content*/}
          <div className='w-full flex flex-col justify-start items-center relative pb-[2vw] overflow-x-hidden'>
            {/*header*/}
            <div className='relative w-[80vw] max-w-[1100px] flex flex-row justify-between items-center py-[4vw]'>
            <img className='header_logo' src='/images/logo.png' style={{}} alt='logo'/>
              <div className='header_visit_button' onClick={navigateToNewPage(CONTENT.cloud_url)}>
                Visit Cloudbreakr
              </div>
            </div>
            {/*end of header*/}

            <img src='/images/big-logo.png' className='w-[90vw] rounded mt-[-10vw] main-logo' alt='logo'/>

            <div className={`w-[80vw] max-w-[1100px] text-white text-center font-bold main_text mt-[-10vw]`}>
              {CONTENT.paragraph_1}
            </div>

            <InfiniteCarousel/>
            <div className='relative w-full'>
              <img src='images/dot.gif' className='absolute w-[50vw] right-[-10vw] top-[-14vw]' alt='gif' style={{objectFit:'contain',height:undefined}}/>
            </div>

            <div className={`w-[80vw] max-w-[1100px] text-white text-center font-bold main_text`} style={{zIndex:9}}>
              {CONTENT.paragraph_2}<br/><br/><br/>
              {CONTENT.paragraph_3}<br/><br/><br/>
              <div className='main_text_3'>{CONTENT.paragraph_4}</div>
            </div>

            {/* <div className='relative flex gap-[5vw] z-10 mt-[2vw] btn1-2-div'>
              <img src='/images/btn1.png' className='download_button' onClick={navigateToNewPage(CONTENT.link_1)} alt='button'/> 
              <img src='/images/btn2.png' className='download_button' onClick={navigateToNewPage(CONTENT.link_2)} alt='button'/> 
            </div> */}


            <div className={`relative w-[80vw] max-w-[1100px] flex d-row-m-col items-center justify-center text-white text-left font-bold main_text margin_102`}>
              <div className='d-40-m-100'>
                <img src='/images/headline_001.png' className='min-w-[300%] relative' style={{left:'-123%'}} alt='title'/>
              </div>
              <div className='paragraph_6 text-white text-left font-bold main_text relative'>
                  {CONTENT.paragraph_5}
              </div>
            </div>



            <div className={`w-[80vw] max-w-[1100px] flex flex-row justify-between flex-wrap mt-[-7vw] box-border`}  style={{zIndex:9,rowGap:24}}>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('1-1')}} src='/images/award_card/award_1/Award_1-1.png' alt='artist'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('1-2')}} src='/images/award_card/award_1/Award_1-2.png' alt='artist'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('1-3')}} src='/images/award_card/award_1/Award_1-3.png' alt='artist'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('1-4')}} src='/images/award_card/award_1/Award_1-4.png' alt='artist'/>
            </div>
            <div className={`w-[80vw] max-w-[1100px] flex flex-row justify-between flex-wrap box-border margin_101`}  style={{zIndex:9,rowGap:24}}>
              <div className='d-22-m-46 relative'>
                <img className='w-full cursor-pointer' onClick={()=>{setisPopup('1-10')}}  src='/images/award_card/award_1/Award_1-10.png' alt='artist'/>
                <img className='absolute w-[25%] top-[-2%] right-[-3%] cursor-pointer' onClick={()=>{setisPopup('1-10')}} src='/images/play.png' alt='play'/>
              </div>
              <div className='d-22-m-46 relative'>
                <img className='w-full cursor-pointer' onClick={()=>{setisPopup('1-5')}}  src='/images/award_card/award_1/Award_1-5.png' alt='artist'/>
                <img className='absolute w-[25%] top-[-2%] right-[-3%] cursor-pointer' onClick={()=>{setisPopup('1-5')}} src='/images/play.png' alt='play'/>
              </div>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('1-6')}} src='/images/award_card/award_1/Award_1-6.png' alt='artist'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('1-7')}} src='/images/award_card/award_1/Award_1-7.png' alt='artist'/>
            </div>
            <div className={`w-[80vw] max-w-[1100px] flex flex-row justify-between flex-wrap box-border margin_101`} style={{zIndex:9,rowGap:24}}>
              <div className='d-22-m-46 d-flex-m-hidden'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('1-8')}} src='/images/award_card/award_1/Award_1-8.png' alt='artist'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('1-9')}} src='/images/award_card/award_1/Award_1-9.png' alt='artist'/>
              <div className='d-22-m-46 d-flex-m-hidden'/>
            </div>

            <div className={`relative w-[80vw] max-w-[1100px] flex d-row-m-col items-center justify-center text-white text-left font-bold main_text margin_100`}  style={{zIndex:8}}>
              <div className='d-40-m-100 topMargin100'>
                <img src='/images/headline_002.png' className='min-w-[300%] relative' style={{left:'-123%'}} alt='title'/>
              </div>
              <div className='paragraph_6 text-white text-left font-bold main_text relative'>
                  {CONTENT.paragraph_6}
              </div>
            </div>
            <div className={`w-[80vw] max-w-[1100px] flex flex-row justify-between mt-[-7vw] box-border flex-wrap `} style={{zIndex:9,rowGap:24}}>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('2-1')}} src='/images/award_card/award_2/Award_2-1.png' alt='artist'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('2-2')}} src='/images/award_card/award_2/Award_2-2.png' alt='artist'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('2-3')}} src='/images/award_card/award_2/Award_2-3.png' alt='artist'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('2-4')}} src='/images/award_card/award_2/Award_2-4.png' alt='artist'/>
            </div>
            <div className={`w-[80vw] max-w-[1100px] flex flex-row justify-between box-border flex-wrap margin_101`} style={{zIndex:9,rowGap:24}}>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('2-5')}} src='/images/award_card/award_2/Award_2-5.png' alt='artist'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('2-6')}} src='/images/award_card/award_2/Award_2-6.png' alt='artist'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('2-7')}} src='/images/award_card/award_2/Award_2-7.png' alt='artist'/>
              <div className='d-22-m-46 relative'>
                <img className='w-full cursor-pointer' onClick={()=>{setisPopup('2-8')}}  src='/images/award_card/award_2/Award_2-8.png' alt='artist'/>
                <img className='absolute w-[25%] top-[-2%] right-[-3%] cursor-pointer' onClick={()=>{setisPopup('2-8')}} src='/images/play_.png' alt='play'/>
              </div>
            </div>
            <div className={`w-[80vw] max-w-[1100px] flex flex-row justify-between box-border flex-wrap margin_101`} style={{zIndex:9,rowGap:24}}>
              <div className='d-22-m-46 d-flex-m-hidden'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('2-9')}} src='/images/award_card/award_2/Award_2-9.png' alt='artist'/>
              <img className='d-22-m-46 cursor-pointer' onClick={()=>{setisPopup('2-10')}} src='/images/award_card/award_2/Award_2-10.png' alt='artist'/>
              <div className='d-22-m-46 d-flex-m-hidden'/>
            </div>


           

            <div className={`relative w-[80vw] max-w-[1100px] flex  d-row-m-col  items-center justify-center text-white text-left font-bold main_text margin_102`}>
              <div className='d-40-m-100'>
                <img src='/images/headline_003.png'  alt='title' className='min-w-[300%] relative' style={{left:'-123%'}}/>
              </div>
              <div className='paragraph_9 text-white text-left font-bold main_text relative phone-hidden'>
                
              </div>
            </div>
      
            <OtherAwardCarousel navigateToNewPage={navigateToNewPage}/>

   

            {/* <div className='w-full relative flex justify-center items-center mt-[10vw]'>
              <div className={`relative w-[80vw] max-w-[1100px] flex d-row-m-col items-center justify-center text-white text-left font-bold main_text mt-[5vw]`}>
                <div className='d-40-m-100 ml-[10%] flex justify-center items-center'>
                  <img src='/images/card5.png'  alt='card' className='w-full card5-img' style={{height:undefined,aspectRatio:527/543}}/>
                </div>
                <div className='d-50-m-100 text-white text-left font-bold main_text relative flex flex-col justify-center items-start ml-[5%] text-centering'>
                  <div className='mt-[1vw]'>{CONTENT.paragraph_7}</div>
                  <div className='mt-[1vw]'>{CONTENT.paragraph_8}</div>
                  <img src='/images/btn3.png' className='download_button_2 mt-[2vw]'  onClick={navigateToNewPage(CONTENT.cloud_url)} alt='button'/>
                </div>
              </div>
            </div> */}
            <div className='w-full relative flex justify-center items-center mt-[6vw]'>
             <div className='text-white text-left font-bold main_text relative flex flex-col justify-center items-start text-centering'>
                <div className='mt-[1vw]'>{CONTENT.paragraph_7}</div>
                <div className='mt-[1vw]'>{CONTENT.paragraph_8}</div>
                <img src='/images/btn3.png' className='download_button_2 mt-[2vw]'  onClick={navigateToNewPage(CONTENT.cloud_url)} alt='button'/>
              </div>
            </div>



            {/*footer*/}
            <div className={`w-[80vw] max-w-[1100px] flex flex-row justify-between items-end mt-[2vw] box-border flex-wrap`}>
              <img className='cursor-pointer'  alt='logo icon' src='/images/logo.png' style={{width:'10%',minWidth:'100px'}}/>
              <div className='flex flex-row gap-[2vw] text-white font-bold main_text_2 flex-wrap'>
                <div className='cursor-pointer'  onClick={navigateToNewPage(CONTENT.btn1_url)}>{CONTENT.btn1}</div>
                <div className='cursor-pointer'  onClick={navigateToNewPage(CONTENT.btn2_url)}>{CONTENT.btn2}</div>
                <div className='cursor-pointer'  onClick={navigateToNewPage(CONTENT.btn3_url)}>{CONTENT.btn3}</div>
                <div className='cursor-pointer'  onClick={navigateToNewPage(CONTENT.btn4_url)}>{CONTENT.btn4}</div>
                <div className='cursor-pointer'  onClick={navigateToNewPage(CONTENT.btn5_url)}>{CONTENT.btn5}</div>
              </div>
            </div>
            <div className={`w-[80vw] max-w-[1100px] flex flex-row justify-between items-center box-border`}>
              <div className='text-white text-xs flex-wrap'>
                {CONTENT.footer_1}
              </div>
              <div className='flex flex-row gap-[8px] text-white font-bold main_text mt-[24px]'>
                <img className='cursor-pointer' onClick={navigateToNewPage(CONTENT.fb_url)} alt='media icon' src='/images/facebook.png' style={{width:'32px'}}/>
                <img className='cursor-pointer' onClick={navigateToNewPage(CONTENT.instagram_url)} alt='media icon' src='/images/instagram.png' style={{width:'32px'}}/>
                <img className='cursor-pointer' onClick={navigateToNewPage(CONTENT.linkedin_url)} alt='media icon' src='/images/linkedin.png' style={{width:'32px'}}/>
              </div>
            </div>
            {/*end of footer*/}

          </div>
          {/*end of main content*/}



          {isPopup&&<div className='fixed inset-0 bg-[#00000090] flex justify-center items-center' style={{zIndex:99}}>
              <div className='w-[90%] max-w-[1100px] relative'>
                  <img className='w-full' src={`/images/popup/pop_up_award/pop_up_awards_${isPopup}.png`} alt='popup'/>
                  <div className='absolute top-0 right-0 cursor-pointer w-[8%] h-[10%]' onClick={()=>{setisPopup(null)}}/>
                  {(isPopup==='2-8'||isPopup==='1-5'||isPopup==='1-10')&&<div className='absolute bottom-[22.5%] w-[39%] flex justify-center items-center  overflow-hidden rounded-[16px]' style={{left:isPopup==='2-8'?'4.9%':'7.9%', borderWidth: 2, borderColor: '#F4D681'}}>
                          <video playsInline 
                              key={`${isPopup}.mp4`}
                              preload="auto"
                              controls={true}
                              controlsList={"nodownload noremoteplayback"}
                              autoPlay={true}
                                loop={false}
                                name="media"
                                className="w-[100%] h-[100%] rounded overflow-hidden"
                          >
                              <source src={`/images/popup/video/${isPopup}.mp4`} type="video/mp4"/>
                          </video>
                   </div>}
                  <PopBtn className="pop-up-btn" style={{position: 'absolute', width:'40%', top:'75%', left:'51%'}}/>
                  <div  onClick={navigateToNewPage(ARTIST_URL[isPopup])} className="pop-up-background flex justify-center items-center" style={{position: 'absolute', width:'40%', top:'75%', left:'51%'}}>
                    <img src='/images/btn_text.png' className='w-[90%]' style={{height:undefined,aspectRatio:1392/98}} alt='text'/>
                  </div>
              </div>
            </div>}


        </div>
      </div>
      {/* {!isInit&&<div className='fixed inset-0 w-full h-full' style={{zIndex:99}} onClick={init}/>} */}
    </div>
  );
}

export default App;
