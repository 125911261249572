export const CONTENT = {
    cloud_url:'https://cloudbreakr.com',
    link_1:'https://cloudbreakr.com/influence-award-2022',
    link_2:'https://docs.google.com/forms/d/e/1FAIpQLSd5GnLotTUwt5kNQwESZil7Cz3uJPcpZvyINgUWHIA0tlf10w/viewform?usp=send_form',
    paragraph_1: '',//`這項盛事得到各方的鼎力支持，媒體報導得獎者及他們的得獎感受，網絡上也得到網媒報導及得獎者粉絲的支持。這證明Cloudbreakr舉辦香港社交媒體風雲榜的初心 - 用數據表揚努力耕耘的內容創作者，凝聚網絡創作力量。  `,
    paragraph_2: `歡迎來到2023社交媒體風雲榜，這個由Cloudbreakr主辦的年度盛事，旨在表彰過去一年內在社交媒體領域取得非凡成就的內容創作者。在這個以數據驅動的時代，我們透過精確的數據分析，挑選出在Instagram、Facebook、YouTube等平台上表現卓越的創作者。他們不僅在社交媒體上擁有龐大的粉絲基礎，而且在每一個帖文中都展示了高度的互動率和話題性。`,
    paragraph_3: `讓我們一同見證這些創作者如何用他們的才華和熱情影響了社交媒體世界，並期待他們在未來繼續締造更多傳奇。 `,
    paragraph_4: `＊2023香港社交媒體風雲榜顯示2023年1月1日至12月31日在Instagram，Facebook及YouTube平台數據，得獎者社交平台的最新數據未能在此反映。`,
    paragraph_5: `綜合Instagram、Facebook及YouTube的平台數據，包括：粉絲增長率、互動率和粉絲真實度，配合Cloudbreakr自家研發的「Influence Power」，最高分數的十名內容創作者獲選為年度風雲人物。`,
    paragraph_6: `以Instagram帖文平均讚好數目為主要評審指標，配合Cloudbreakr自家研發的「Influence Power」，最高分數的十位內容創作者獲選為年度網民最愛。`,
    paragraph_7: `想緊貼最新Influencers數據，`,
    paragraph_8: `立即申請免費用戶體驗!`,
    paragraph_9: ``,
    btn1: `Help Center`,
    btn1_url:'http://community.cloudbreakr.com/',
    btn2: `Contact`,
    btn2_url:'https://cloudbreakr.com/contact',
    btn3: `Pricing`,
    btn3_url:'https://cloudbreakr.com/pricing',
    btn4: `About`,
    btn4_url:'https://cloudbreakr.com/about',
    btn5: `Products`,
    btn5_url:'https://cloudbreakr.com/product',
    footer_1: '',
    fb_url:'https://www.facebook.com/cloudbreakr/',
    instagram_url:'https://www.instagram.com/cloudbreakr/',
    linkedin_url:'https://www.linkedin.com/company/advwhere/',
}

export const ARTIST_URL = {
    '1-1':'https://cloudbreakr.com/mingjai14',
    '1-2':'https://cloudbreakr.com/lucy.is.good',
    '1-3':'https://cloudbreakr.com/jlouofficial',
    '1-4':'https://cloudbreakr.com/torres_pit',
    '1-5':'https://cloudbreakr.com/misselvani',
    '1-6':'https://cloudbreakr.com/jeffreyngai',
    '1-7':'https://cloudbreakr.com/ansonlht',
    '1-8':'https://cloudbreakr.com/minchen333',
    '1-9':'https://cloudbreakr.com/tysonyoshi',
    '1-10':'https://cloudbreakr.com/charmaine_sheh',
    '2-1':'https://cloudbreakr.com/coffee89921',
    '2-2':'https://cloudbreakr.com/krisssica',
    '2-3':'https://cloudbreakr.com/kaaying219',
    '2-4':'https://cloudbreakr.com/thaimay_spicy',
    '2-5':'https://cloudbreakr.com/wong_kar_wai',
    '2-6':'https://cloudbreakr.com/21kwanmoon',
    '2-7':'https://cloudbreakr.com/hillarylau',
    '2-8':'https://cloudbreakr.com/yiminghay',
    '2-9':'https://cloudbreakr.com/ivyysooo',
    '2-10':'https://cloudbreakr.com/keung_show',

    '3-1':'https://cloudbreakr.com/mung.yufatho317',
    '3-2':'https://cloudbreakr.com/fatho317',
    '3-3':'https://cloudbreakr.com/perfectcontinuouseat_',


    '3-4':'https://cloudbreakr.com/cusonlo',
    '3-5':'https://cloudbreakr.com/surrealhk',
    '3-6':'https://cloudbreakr.com/trialanderror924',
    '3-7':'https://cloudbreakr.com/baby_elly312',


    '3-8':'https://cloudbreakr.com/pinkytszyan',
    '3-9':'https://cloudbreakr.com/karenyeahhh',
    
    '3-10':'https://cloudbreakr.com/iwalkutake',
    '3-11':'https://cloudbreakr.com/saidorsi',

    '3-12':'https://cloudbreakr.com/water_wild_month',
    '3-13':'https://cloudbreakr.com/amber.tcy',
    '3-14':'https://cloudbreakr.com/arnoldkwokk',


    '3-15':'https://cloudbreakr.com/mya.desu',
    '3-16':'https://cloudbreakr.com/hoitingmui',

    '3-17':'https://cloudbreakr.com/mm.millmilk',

    '3-18':'https://cloudbreakr.com/harbourcity',
}